import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@customer-portal/environments';
import { FileUpload, getContentType } from '@customer-portal/shared';

import { DocumentDeleteDto } from '../dtos/document-delete.dto';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  constructor(private readonly httpClient: HttpClient) {}

  downloadDocument(
    contactId: string | null,
    documentId: number,
    fileName?: string,
  ): Observable<HttpResponse<Blob>> {
    const { documentsApi } = environment;

    const query = `contactId=${contactId ?? '1'}&documentId=${documentId}`;
    const url = `${documentsApi}/download?${query}`;

    const headers = fileName
      ? new HttpHeaders({
          Accept: getContentType(fileName),
        })
      : undefined;

    return this.httpClient.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers,
    });
  }

  deleteDocument(id: number): Observable<DocumentDeleteDto> {
    const { documentsApi } = environment;

    return this.httpClient.delete<DocumentDeleteDto>(
      `${documentsApi}/DeleteDocument?documentId=${id}`,
    );
  }

  uploadDocument(
    url: string,
    file: File,
    contactId: string,
    auditId: string,
    findingId?: string,
  ): Observable<FileUpload> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const headers = new HttpHeaders({ SKIP_LOADING: 'true' });

    const params = findingId
      ? this.uploadFindingDocumentParams(contactId, auditId, findingId)
      : this.uploadAuditDocumentParams(contactId, auditId);

    const { documentsApi } = environment;

    return this.httpClient.post<FileUpload>(`${documentsApi}${url}`, formData, {
      headers,
      params,
    });
  }

  uploadAuditDocumentParams(contactId: string, auditId: string): HttpParams {
    return new HttpParams().set('ContactId', contactId).set('AuditId', auditId);
  }

  uploadFindingDocumentParams(
    contactId: string,
    auditId: string,
    findingId: string,
  ): HttpParams {
    return new HttpParams()
      .set('ContactId', contactId)
      .set('AuditId', auditId)
      .set('FindingId', findingId);
  }
}
